import React from 'react'
import '../Styles/Aboutus.css'
import Navbar from './Navbar'
import arrow from '../svg/arrow.svg'
import Marquee from "react-fast-marquee";
import star from '../svg/star.svg'
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const Aboutus = () => {
  return (
    <>
      <div className='aboutus'>
      <Helmet>
         <title>About Kane Smith Travels & Taxis | Best Taxi Service in Ooty</title> 
         <meta name='description' content='Learn about Kane Smith Travels & Taxis, your trusted partner for safe, reliable, and affordable taxi services in Ooty. Discover our commitment to excellent customer service and unforgettable Ooty experiences.'/>

    </Helmet>
        <div className='aboutus1'>

           <div className='ab2'>
                <Navbar/>
                <div className='cmid'>
                <h1 className='ab01' >About Us</h1>
                </div>
         </div>

         <div className='c2'>
         <h1 className='c21'><a href='/plan'> Plan Your Trip With Kane Smith Travels </a> </h1>
            <div className='c22'><a href='/plan'><img src={arrow} className='arrimg'/></a></div>
         </div>
         <hr></hr>

        <div className='ab4'>
            <div className='ab41'>
            <div className='ab42'>ABOUT KANE SMITH</div>
            <h1 className='ab43'>Kane Smith Travels & Taxis: Best Sightseeing Cab Services in Ooty</h1>
       
            <div className='ab5' id='ab5'>
        <div className='ab51'>
            <img src='/img/car2.png'></img>
        </div>
      </div>
      <div >
        <h1 className='ab43 ab43aa'> Unveiling Hidden Gems & Beloved Classics</h1>
        {/* <br></br> */}
         <div className='ab44'>Our passion lies in exceeding expectations. At Kane Smith Travels & Taxis, we go beyond the typical tourist spots, offering curated itineraries that unveil Ooty's hidden treasures alongside its iconic landmarks like the Botanical Gardens and Doddabetta Peak. We believe in revealing the lesser-known wonders of Ooty, ensuring you experience both its famous sights and its secret delights.</div>
         <br></br>
         <h1 className='ab43 ab43aa'> Your Comfort is Our Priority:</h1>
        <br></br>
         <div className='ab44'>A comfortable journey enhances the entire travel experience. That's why we provide a fleet of well-maintained cabs and employ knowledgeable drivers who expertly navigate Ooty's scenic routes. Whether you're traveling through lush tea estates or winding through misty hills, our focus is on making your journey as pleasant and safe as possible.
         </div>
         <br></br>   <h1 className='ab43 ab43aa'> Memories Crafted, Not Manufactured:</h1>
        <br></br>
         <div className='ab44'>We understand that every traveler seeks a unique experience. Unlike generic packages, we collaborate with you to design a customized Ooty Trip Package that reflects your interests and travel style. Whether it's a romantic escape, an adventurous expedition, or a family exploration, we ensure your trip is tailored to your desires.
         </div>
         <br></br>   <h1 className='ab43 ab43aa'> Discover Ooty's Wonders:         </h1>
        <br></br>
         <div className='ab44'>Explore Ooty's famed attractions: Dhoddabetta Peak, the Tea Factory & Museum, Ooty Lake, Thread Garden, Rose Garden, Arboretum, Choco Story, Science Museum (Stone House), Botanical Garden, and the historic Stephen Church. Additionally, uncover the beauty of Ketty Valley View, the army museum at the Madras Regimental Centre, Sims Park, Lambs Rock, Dolphin's Nose Viewpoint, Sleeping Lady, and Catherine Waterfalls.

         <h1 className='ab43 ab43aa'>  Let the Adventure Begin:        </h1>
         {/* <br></br> */}

         <div className='ab44'>Contact Kane Smith Travels & Taxis today to unlock the magic of Ooty. Let us help you create memories that will last a lifetime. From the iconic to the hidden, your journey with us will be one of discovery, comfort, and unforgettable experiences.
         <br></br>
         <br></br>
        
         Embark on an adventure with Kane Smith Travels & Taxis and let us guide you through the captivating beauty of Ooty, the "Queen of Hills."</div>
         <br></br>
         </div> 

      </div>
           
            </div>
        </div>

        
      <div className='ab5' id='ab6'>
        <div className='ab51'>
            <img src='/img/car2.webp'></img>
        </div>
      </div>


      <div className='c5' id='abcars'>
<div className='c51' id='abcars2'>

<div className='c051'>
        <div className='c052'>Our Vehicles</div>
        <br></br>
        <h1 className='c053'> Every Vehicle Tells a Tale</h1>
</div>
</div>


<div className='c52' id='abcars1'>
    <div className='c521'>
      
    <div className='c5211'>
    <div className='c056'> <img src='/img/car21.webp'></img></div>
    <div className='c057'>
      <div className='c054'>Swift Dzire</div>
      </div>
     </div>


    <div className='c5211'>
    <div className='c056'> <img src='/img/car22.webp'></img></div>
    <div>
    <div className='c054'>Toyota Etios</div>
     
</div>
    </div>
 <div className='c5211'>
    <div className='c056'> <img src='/img/car23.webp'></img></div>
    <div>
    <div className='c054'>Swift Dzire</div>
     
</div>
    </div>
    <div className='c5211'>
    <div className='c056'> <img src='/img/car24.webp'></img></div>
    <div>
    <div className='c054'>Toyota Innova</div>
     
</div>
    </div>
</div>
  

  </div>   
  </div>       
    
    

  <div className='c6'>
 <div className='c051'>
        <div className='c052'>Testimonials</div>
        <br></br>
        <div className='c053'>What Our Guests Say About Ooty with Us</div>
</div>
  <div className='c61'>
    
  <Marquee  >

        <div className='ccc'>
          <img src={star}></img>
          
          <div className='c66'>"Kane Smith Travels offers exceptional cab services. Skilled drivers make the journey enjoyable and safe. A reliable choice for seamless travel experiences, ensuring comfort and trust in every ride."
 </div>
         </div>
        <div className='gap'>ccc</div>
         <div className='ccc'>
          <img src={star}></img>

          <div className='c66'>"Kane Smith Travels impresses with top-notch cab services. Expert drivers navigate smoothly, making the journey comfortable and secure. A trusted option for reliable transportation and a pleasant travel experience."
</div>
         </div>
         <div className='gap'>ccc</div>

         <div className='ccc'>
          <img src={star}></img>

          <div className='c66'>"Experience excellence with Kane Smith Travels. Their cab services are outstanding, with skilled drivers ensuring a safe and enjoyable ride. A reliable choice for quality transportation and a delightful travel experience."</div>
         </div>
         <div className='gap'>ccc</div>


      
</Marquee>
  </div>

</div>
<div className='gap'> 

</div>

<Footer/>

        </div>
      </div>
    </>
  )
}

export default Aboutus