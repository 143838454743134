import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Pages/Navbar';
import Loadingpage from './Components/Pages/Loadingpage';
import Pages from './Pages';
import Menu from './Components/Pages/Menu';

function App() {
  return (
<>
<Pages/>
</>
  );
}

export default App;
